export default {
    blogList: [
        {
            title: 'How to Fix GB WhatsApp Ban Issue in 2025',
            desc: 'Using GB WhatsApp comes with its risks, the most notable being account bans from the official WhatsApp platform. As modified versions of WhatsApp like GB WhatsApp are not officially endorsed, users often encounter temporary or even permanent bans.',
            url: 'fix-gbwhatsapp-ban-issue',
            img: require('@/assets/blog3-1.webp'),
            alt: 'gbwhatsapp ban issue'
        },
        {
            title: 'FM WhatsApp VS GB WhatsApp: Which Fit you?',
            desc: 'In the world of WhatsApp mods, FM WhatsApp and GB WhatsApp are two of the most popular choices for users seeking features beyond what the official WhatsApp provides. But which one is the right fit for you? Let’s dive into the details to help you decide.',
            url: 'fmwhatsapp-gbwhatsapp',
            img: require('@/assets/blog3-2.webp'),
            alt: 'FM WhatsApp VS GB WhatsApp'
        }
    ],
    urlMap: {
        0: 'fix-gbwhatsapp-ban-issue',
        1: 'fmwhatsapp-gbwhatsapp',
    }
}