<template>
<div id="head">
    <div class="header">
        <router-link class="head-logo-row" style="cursor: pointer" v-if="validSite(['waproapk.com'])" :to="makeURL('')">
            <img src="../assets/GBPRO@2x.webp" class="head-img" alt="logo" />
            <div class="head-name">GB WhatsApp</div>
        </router-link>
        <div class="head-logo-row" @click="goto('')" style="cursor: pointer" v-else>
            <img src="../assets/GBPRO@2x.webp" class="head-img" alt="logo" />
            <div class="head-name">GB WhatsApp</div>
        </div>
        <div class="nav-row" v-if="deviceType !== 'mobile'">
            <!-- Home -->
            <div class="nav-item" v-if="validSite(['waproapk.com'])">
                <router-link class="nocolor" :to="makeURL('')">
                    {{this.navLang['waproapk.com'][curLang][0]}}
                </router-link>
            </div>
            <div class="nav-item" v-else>
                <a class="nocolor" href="/">
                    Home
                </a>
            </div>
            <!-- FM -->
            <div class="nav-item" v-if="validSite(['waplusapk.click'])">
                <a class="nocolor" href="/fm-whatsapp-apk">
                    FM WhatsApp
                </a>
            </div>
            <!-- plus -->
            <div class="nav-item" v-if="validSite(['waproapk.com'])">
                <router-link class="nocolor" :to="makeURL('whatsapp-plus-apk')">
                    {{this.navLang['waproapk.com'][curLang][1]}}
                </router-link>
            </div>
            <!-- PC -->
            <div class="nav-item" v-if="validSite(['whatsapppro.org'])">
                <a class="nocolor" href="/gb-whatsapp-for-pc">
                    PC
                </a>
            </div>
            <!-- iPhone -->
            <div class="nav-item" v-if="validSite(['waproapk.com'])">
                <router-link class="nocolor" :to="makeURL('gbwhatsapp-for-iphone')">
                    {{this.navLang['waproapk.com'][curLang][2]}}
                </router-link>
            </div>
            <div class="nav-item" v-else-if="validSite(['waproapk.net'])">
                <a class="nocolor" href="/gbwhatsapp-ios">
                    iOS
                </a>
            </div>
            <!-- old version -->
            <div class="nav-item" v-if="validSite(['whatsapppro.org'])">
                <a class="nocolor" href="/gb-whatsapp-old-versions">
                    Versions
                </a>
            </div>
            <!-- More mods -->
            <div class="more-mods nav-item" v-if="validSite(['gbwhatsapk.chat'])">
                More Mods
                <div class="mod-wrapper">
                    <div class="mod-item">
                        <a class="nocolor" href="/an-whatsapp">
                            ANWhatsApp
                        </a>
                    </div>
                    <div class="mod-item">
                        <a class="nocolor" href="/ob-whatsapp">
                            OBWhatsApp
                        </a>
                    </div>
                    <div class="mod-item">
                        <a class="nocolor" href="/jt-whatsapp">
                            JTWhatsApp
                        </a>
                    </div>
                    <div class="mod-item">
                        <a class="nocolor" href="/blue-whatsapp">
                            BlueWhatsApp
                        </a>
                    </div>
                </div>
            </div>
            <div class="nav-item" v-else-if="validSite(['whatsapppro.org','gbwhatsapk.net'])">
                <a href="/more-mods" class="nocolor">More Mods</a>
            </div>

            <div class="nav-item" v-if="validSite(['gbwhatsapk.org'])">
                <a href="/gb-whatsapp-pro" class="nocolor">
                    GB WhatsApp Pro
                </a>
            </div>
            
            <!-- Blog -->
            <!-- 多语言blog -->
            <div class="nav-item" v-if="validSite(['waproapk.com'])">
                <router-link class="nocolor" :to="makeURL('blogs')" >
                    {{this.navLang['waproapk.com'][curLang][3]}}
                </router-link>
            </div>
            <div class="nav-item" v-if="isValidHost || validSite(['whatsapppro.org','gbwhatsapk.net'])">
                <a class="nocolor" href="/blogs">
                    Blog
                </a>
            </div>
            <!-- Download -->
            <div class="nav-item" v-if="validSite(['gbwhatsapk.org'])">
                <a class="nocolor" href="/download-gbwhatsapp">
                    Download
                </a>
            </div>
            <div class="nav-item" v-else-if="validSite(['gbapks.org.in'])">
                <a class="nocolor" href="/gbwhatsapp-update">
                    Download
                </a>
            </div>
            <div class="nav-item" v-else-if="validSite(['whatsapppro.org'])">
                <a class="nocolor" href="/download-gb-whatsapp-new">
                    Download
                </a>
            </div>
            <div class="nav-item" v-else-if="validSite(['waproapk.net'])">
                <a class="nocolor" href="/download-gbwhatsapp-apk">
                    Download
                </a>
            </div>
            <div class="nav-item" v-else-if="validSite(['waproapk.com'])">
                <router-link class="nocolor" :to="makeURL('download-gbwhatsapp')">
                    {{this.navLang['waproapk.com'][curLang][4]}}
                </router-link>
            </div>
            <div class="nav-item" v-else>
                <a class="nocolor" :href="apk">
                    Download
                </a>
            </div>
            <!-- Privacy for gbapks.com -->
            <div class="nav-item" v-if="isValidHost">
                <a class="nocolor" href="/privacy">
                    Privacy
                </a>
            </div>
            <!-- Language selector -->
            <div v-if="validSite(['waproapk.com'])" class="nav-item lang">
                <div @click="show" class="displayitem">
                    <img v-if="deviceType !== 'mobile'" :src="require('../assets/' + countryMap[curLang] + '.webp')" alt="" />
                    <a class="nocolor">{{ curLang }}</a>
                    <svg viewBox="0 0 330 512" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em">
                        <path fill="#fff" d="M305.913 197.085c0 2.266-1.133 4.815-2.833 6.514L171.087 335.593c-1.7 1.7-4.249 2.832-6.515 2.832s-4.815-1.133-6.515-2.832L26.064 203.599c-1.7-1.7-2.832-4.248-2.832-6.514s1.132-4.816 2.832-6.515l14.162-14.163c1.7-1.699 3.966-2.832 6.515-2.832 2.266 0 4.815 1.133 6.515 2.832l111.316 111.317 111.316-111.317c1.7-1.699 4.249-2.832 6.515-2.832s4.815 1.133 6.515 2.832l14.162 14.163c1.7 1.7 2.833 4.249 2.833 6.515z"></path>
                    </svg>
                </div>
                <div class="dropdown" v-show="showState">
                    <div class="item" v-for="item in dropdownArr" :key="item" @click="gotoPageLang(item)">
                        <img :src="require('../assets/' + countryMap[item] + '.webp')" alt="" />
                        {{ item }}
                    </div>
                </div>
            </div>
            <div v-else-if="validSite(['whatsapppro.org', 'waproapk.net', 'gbwhatsapk.net', 'gbapps.homes', 'waplusapk.click'])" class="nav-item lang">
                <div @click="show" class="displayitem">
                    <img v-if="deviceType !== 'mobile'" :src="require('../assets/' + countryMap[curLang] + '.webp')" alt="" />
                    <a class="nocolor">{{ curLang }}</a>
                    <svg viewBox="0 0 330 512" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em">
                        <path fill="#fff" d="M305.913 197.085c0 2.266-1.133 4.815-2.833 6.514L171.087 335.593c-1.7 1.7-4.249 2.832-6.515 2.832s-4.815-1.133-6.515-2.832L26.064 203.599c-1.7-1.7-2.832-4.248-2.832-6.514s1.132-4.816 2.832-6.515l14.162-14.163c1.7-1.699 3.966-2.832 6.515-2.832 2.266 0 4.815 1.133 6.515 2.832l111.316 111.317 111.316-111.317c1.7-1.699 4.249-2.832 6.515-2.832s4.815 1.133 6.515 2.832l14.162 14.163c1.7 1.7 2.833 4.249 2.833 6.515z"></path>
                    </svg>
                </div>
                <div class="dropdown" v-show="showState">
                    <div class="item" v-for="item in dropdownArr" :key="item" @click="gotoLang(item)">
                        <img :src="require('../assets/' + countryMap[item] + '.webp')" alt="" />
                        {{ item }}
                    </div>
                </div>
            </div>
        </div>
        <!-- mobile language selector -->
        <div class="nav-row" v-if="deviceType == 'mobile'">
            <div v-if="validSite(['whatsapppro.org', 'waproapk.net', 'gbwhatsapk.net', 'gbapps.homes', 'waplusapk.click'])" class="nav-item lang">
                <div @click="show()" class="displayitem">
                    <img v-if="deviceType !== 'mobile'" :src="require('../assets/' + countryMap[curLang] + '.webp')" alt="" />
                    <a class="nocolor">{{ curLang }}</a>
                    <svg viewBox="0 0 330 512" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em">
                        <path fill="#fff" d="M305.913 197.085c0 2.266-1.133 4.815-2.833 6.514L171.087 335.593c-1.7 1.7-4.249 2.832-6.515 2.832s-4.815-1.133-6.515-2.832L26.064 203.599c-1.7-1.7-2.832-4.248-2.832-6.514s1.132-4.816 2.832-6.515l14.162-14.163c1.7-1.699 3.966-2.832 6.515-2.832 2.266 0 4.815 1.133 6.515 2.832l111.316 111.317 111.316-111.317c1.7-1.699 4.249-2.832 6.515-2.832s4.815 1.133 6.515 2.832l14.162 14.163c1.7 1.7 2.833 4.249 2.833 6.515z"></path>
                    </svg>
                </div>
                <div class="dropdown showCountry" v-show="showState">
                    <div class="item" v-for="item in dropdownArr" :key="item" @click="gotoLang(item)">
                        <img :src="require('../assets/' + countryMap[item] + '.webp')" alt="" />
                        {{ item }}
                    </div>
                </div>
            </div>

            <div class="nav-item menu" @click="toggleMenu()">
                <img src="@/assets/menu.svg" alt="menu">
            </div>
        </div>
    </div>



    <!-- dropdown menu for mobile -->
    <div class="dropdown-menu" v-show="menu">
        <div class="nav-item" v-if="validSite(['waproapk.com'])">
            <router-link class="nocolor" :to="makeURL('')">
                {{this.navLang['waproapk.com'][curLang][0]}}
            </router-link>
        </div>
        <div class="nav-item" v-else>
            <a class="nocolor" href="/">
                Home
            </a>
        </div>
        <div class="nav-item" v-if="validSite(['waplusapk.click'])">
            <a class="nocolor" href="/fm-whatsapp-apk">
                FM WhatsApp
            </a>
        </div>
        <!-- plus -->
        <div class="nav-item" v-if="validSite(['waproapk.com'])">
            <router-link class="nocolor" :to="makeURL('whatsapp-plus-apk')">
                {{this.navLang['waproapk.com'][curLang][1]}}
            </router-link>
        </div>
        <div class="nav-item" v-if="validSite(['whatsapppro.org'])">
            <a class="nocolor" href="/gb-whatsapp-for-pc">
                PC
            </a>
        </div>
        <!-- iPhone -->
        <div class="nav-item" v-if="validSite(['waproapk.com'])">
            <router-link class="nocolor" :to="makeURL('gbwhatsapp-for-iphone')">
                {{this.navLang['waproapk.com'][curLang][2]}}
            </router-link>
        </div>
        <div class="nav-item" v-else-if="validSite(['waproapk.net'])">
            <a class="nocolor" href="/gbwhatsapp-ios">
                iOS
            </a>
        </div>
        <div class="nav-item" v-if="validSite(['whatsapppro.org'])">
            <a class="nocolor" href="/gb-whatsapp-old-versions">
                Versions
            </a>
        </div>

        <div class="nav-item " v-if="validSite(['gbwhatsapks.chat'])">
            <a class="nocolor" href="/an-whatsapp">
                AN WhatsApp
            </a>
        </div>
        <div class="nav-item " v-if="validSite(['gbwhatsapks.chat'])">
            <a class="nocolor" href="/ob-whatsapp">
                OB WhatsApp
            </a>
        </div>
        <div class="nav-item " v-if="validSite(['gbwhatsapks.chat'])">
            <a class="nocolor" href="/jt-whatsapp">
                JT WhatsApp
            </a>
        </div>
        <div class="nav-item" v-if="validSite(['gbwhatsapks.chat'])">
            <a class="nocolor" href="/blue-whatsapp">
                Blue WhatsApp
            </a>
        </div>
        <div class="nav-item" v-if="validSite(['gbwhatsapk.org'])">
            <a href="/gb-whatsapp-pro" class="nocolor">
                GB WhatsApp Pro
            </a>
        </div>
        <div class="nav-item" v-if="validSite(['whatsapppro.org','gbwhatsapk.net'])">
            <a href="/more-mods" class="nocolor">More Mods</a>
        </div>
        
        <div class="nav-item" v-if="validSite(['waproapk.com'])">
            <router-link class="nocolor" :to="makeURL('blogs')" >
                {{this.navLang['waproapk.com'][curLang][3]}}
            </router-link>
        </div>
        <div class="nav-item" v-else-if="isValidHost || validSite(['whatsapppro.org','gbwhatsapk.net'])">
            <a class="nocolor" href="/blogs">
                Blog
            </a>
        </div>
        <!-- Download -->
        <div class="nav-item" v-if="validSite(['gbwhatsapk.org'])">
            <a class="nocolor" href="/download-gbwhatsapp">
                Download
            </a>
        </div>
        <div class="nav-item" v-else-if="validSite(['gbapks.org.in'])">
            <a class="nocolor" href="/gbwhatsapp-update">
                Download
            </a>
        </div>
        <div class="nav-item" v-else-if="validSite(['whatsapppro.org'])">
            <a class="nocolor" href="/download-gb-whatsapp-new">
                Download
            </a>
        </div>
        <div class="nav-item" v-else-if="validSite(['waproapk.net'])">
            <a class="nocolor" href="/download-gbwhatsapp-apk">
                Download
            </a>
        </div>
        <div class="nav-item" v-else-if="validSite(['waproapk.com'])">
            <router-link class="nocolor" :to="makeURL('download-gbwhatsapp')">
                {{this.navLang['waproapk.com'][curLang][4]}}
            </router-link>
        </div>
        <div class="nav-item" v-else>
            <a class="nocolor" :href="apk">
                Download
            </a>
        </div>
        <div class="nav-item" v-if="isValidHost">
            <a class="nocolor" href="/privacy">
                Privacy
            </a>
        </div>
    </div>

</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'

export default {
    name: 'TopheadComponent',
    data() {
        return {
            curLang: 'ENGLISH',
            countryMap: {
                ENGLISH: 'us',
                'Bahasa Indonesia': 'id',
                Português: 'pt',
                Español: 'es',
                Deutsch: 'de',
                العربية: 'ar',
                हिंदी: 'hi',
            },
            dropdownArr: ['ENGLISH', 'Bahasa Indonesia', 'Português', 'Español', 'Deutsch', 'العربية', 'हिंदी'],
            showState: false,
            menu: false,
            navLang: {
                'waproapk.com':{
                    'ENGLISH':['Home','WhatsApp Plus','iPhone','Blog','Download'],
                    'Bahasa Indonesia':['Beranda','WhatsApp Plus','iPhone','Blog','Unduh'],
                    'Português':['Casa','WhatsApp Plus','iPhone','Blog','Baixar'],
                }
            }
        }
    },
    created() {
        if (typeof localStorage == 'undefined') return
        console.log(22222, this.host)
        let preLang = localStorage.getItem('lang')
        console.log('pre', preLang)
        if (preLang && preLang.length) {
            this.curLang = preLang
        }
        this.dropdownArr.splice(0, this.dropdownArr.length, ...this.dropdownArr.filter((e) => e !== this.curLang))

        // 多语言 - 部分站点需要特殊处理
        if (this.host.includes('gbwhatsapk.net')) {
            this.dropdownArr = ['ENGLISH', 'Bahasa Indonesia', 'Português', 'العربية', 'हिंदी']
        } else if (this.host.includes('waplusapk.click')) {
            this.dropdownArr = ['ENGLISH', 'Português', 'Español']
        } else if (this.host.includes('waproapk.com')) {
            this.dropdownArr = ['ENGLISH', 'Bahasa Indonesia', 'Português']
        }
    },
    props: {
        apk: {
            type: String,
            required: true,
        }
    },
    computed: {
        ...mapGetters(['showDebug', 'host', 'deviceType']),
        isValidHost() {
            const validLocalhost = /^localhost(:\d+)?$/
            const validDomain = /^(https?:\/\/)?(www\.)?gbapks\.com$/
            if (!this.host) {
                return false
            }
            return validLocalhost.test(this.host) || validDomain.test(this.host)
        },

        deviceType() {
            return this.$store.state.deviceType
        },

        makeURL() {
            if (this.curLang==='ENGLISH') {
                return (param) => `/${param}`;
            }
            return (param) => `/${this.countryMap[this.curLang]}/${param}`;
        }
    },
    methods: {
        validSite(arr) {
            // console.log('validSite: ',arr)

            if (this.host.length === 0) {
                return true
            }

            return arr.some((e) => {
                return this.host.indexOf(e) >= 0
            })
        },
        show() {
            if (this.deviceType !== 'mobile') {
                return
            }
            this.showState = !this.showState
            this.menu = false;
        },
        gotoLang(item) {
            if (typeof localStorage == 'undefined') return
            localStorage.setItem('lang', item)

            if (item === 'ENGLISH') {
                window.location.href = '/'
                return
            }
            window.location.href = '/' + this.countryMap[item]
        },

        gotoPageLang(item) {

            if (typeof localStorage == 'undefined') return
            localStorage.setItem('lang', item)

            const newLang = this.countryMap[item] || '';

            if (this.curLang === 'ENGLISH') {
                if (item === 'ENGLISH') {
                    return;
                } else {
                    const curPath = window.location.pathname.split('/').slice(1);
                    window.location.href = `/${newLang}/${curPath.join('/')}`;
                }
            } else {
                if (item === 'ENGLISH') {
                    const curPath = window.location.pathname.split('/').slice(2);
                    window.location.href = `/${curPath.join('/')}`
                } else {
                    const curPath = window.location.pathname.split('/').slice(2);
                    window.location.href = `/${newLang}/${curPath.join('/')}`;
                }
            }

            // window.location.href = '/' + this.countryMap[item]

        },
        goto(addr) {
            window.location.href = '/' + addr
        },
        toggleMenu() {
            this.menu = !this.menu;
            console.log('Menu state:', this.menu);
            this.showState = false; // Close language selector
            console.log('Language selector state:', this.showState);
        },

        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.menu = false;
                this.showState = false; // Close both dropdowns when clicking outside
            }
        },
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside);
    },

}
</script>

<style lang="scss" scoped></style>
